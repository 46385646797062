<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="getReceiptDataError || notEnoughData || (receiptData && receiptData.finalStatus === 'ERROR' )">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5">{{$t('not-enough-data')}}</p>
                <p v-else-if="getReceiptDataError" class="alert alert-danger mt-5">{{getReceiptDataError}} {{$t('request-unsuccessful')}}</p>
                <p v-else-if="receiptData && (receiptData.finalStatus === 'ERROR' || receiptData.finalStatus === 'PENDING')" class="alert alert-danger mt-5">
                    <strong>{{$t('amount')}}:</strong> {{(receiptData.amount/100)}}{{receiptData.currencySymbol}}<br/><br/>
                    <strong>{{$t('payment-reference')}}:</strong> {{receiptData.paymentRef}}
                </p>

                <redirect-button v-if="receiptData && receiptData.brandUrl" :brandUrl="receiptData.brandUrl"></redirect-button>

                <redirect-button v-if="brandUrl" :brandUrl="brandUrl"></redirect-button>
                <a v-if="receiptData && receiptData.redirectUrl" :href="receiptData.redirectUrl" class="btn btn-success mt-3 ml-3">{{$t('redirect')}}</a>
                <p class="mt-4" v-if="receiptData && receiptData.redirectUrl"><strong>{{$t('redirect-processor')}} {{seconds}} ...</strong></p>
            </template>
            <template v-else-if="receiptData && receiptData.finalStatus === 'PENDING'">
                <warning></warning>
                <p v-if="notEnoughData" class="alert alert-danger mt-5">{{$t('not-enough-data')}}</p>
                <p v-else-if="getReceiptDataError" class="alert alert-danger mt-5">{{getReceiptDataError}} {{$t('request-unsuccessful')}}</p>
                <p v-else-if="receiptData && receiptData.finalStatus === 'PENDING'" class="">
                    <h6>{{$t('pending-transaction-info')}}</h6>
                    <div class="alert alert-warning mt-5">
                        <strong>{{$t('amount')}}:</strong> {{(receiptData.amount/100)}}{{receiptData.currencySymbol}}<br/><br/>
                        <strong>{{$t('payment-reference')}}:</strong> {{receiptData.paymentRef}}
                    </div>
                </p>

                <redirect-button v-if="receiptData && receiptData.brandUrl" :brandUrl="receiptData.brandUrl"></redirect-button>
                <redirect-button v-if="brandUrl" :brandUrl="brandUrl"></redirect-button>
                <a v-if="receiptData && receiptData.redirectUrl" :href="receiptData.redirectUrl" class="btn btn-success mt-3 ml-3">{{$t('redirect')}}</a>
                <p class="mt-4" v-if="receiptData && receiptData.redirectUrl"><strong>{{$t('redirect-processor')}} {{seconds}} ...</strong></p>
            </template>
            <template v-else-if="receiptData && receiptData.finalStatus === 'SUCCESS'">
                <!--Success-->
                <success :type="receiptData.paymentType === 'DEPOSIT' ? 'deposit' : 'payout'"></success>
                <p v-if="receiptData.finalStatus === 'SUCCESS'" class="alert alert-success mt-5">
                    <strong>{{$t('amount')}}:</strong> {{(receiptData.amount/100)}}{{receiptData.currencySymbol}}<br/><br/>
                    <strong>{{$t('payment-reference')}}:</strong> {{receiptData.paymentRef}}
                </p>
                <redirect-button v-if="receiptData && receiptData.brandUrl" :brandUrl="receiptData.brandUrl"></redirect-button>
                <redirect-button v-if="brandUrl" :brandUrl="brandUrl"></redirect-button>
            </template>
            <div v-else-if="showLoader">
                <div class="mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {getReceiptData} from '../../services/backend.service';

    export default {
        name: 'Receipt',
        data(){
            return{
                showLoader: true,
                intervalId: null,
                secondsIntervalId: null,
                numberOfBackendCalls: 0,
                notEnoughData: null,
                getReceiptDataError: null,
                receiptData: null,
                seconds: 10,
            }
        },
        components:{
            Error: () => import('./Error'),
            Success: () => import('./Success'),
            Warning: () => import('./Warning'),
            RedirectButton: () => import('./RedirectButton'),
        },
        mounted() {
            let transactionId = this.$router.history.current.query.transactionid;
            this.brandUrl = this.$router.history.current.query.brandUrl;
            let params = this.$router.history.current.query.params;
            if (!transactionId)
                transactionId = this.$router.history.current.query.op1;
            if (!transactionId) {
              transactionId = this.$route.params.transactionid
            }
            if(transactionId){
                // If the paymentStatus is still pending we retry the call in 5 seconds. The max number of retries is 4.
                this.getReceipt(transactionId, params);
                this.intervalId = setInterval(function () {
                    this.numberOfBackendCalls = this.numberOfBackendCalls + 1;
                    this.getReceipt(transactionId, params);
                }.bind(this), 5000)
            } else {
                this.notEnoughData = true;
            }
        },
        methods: {
            getReceipt: function (transactionId, params) {
                getReceiptData(transactionId, params).then(res => {
                  debugger;
                    if (res.data.success && res.data.data) {
                        if(res.data.data.redirectUrl){
                            clearInterval(this.intervalId);
                            this.receiptData = res.data.data;
                            this.secondsIntervalId = setInterval(function() {
                                this.seconds = this.seconds -1;
                                if(this.seconds === 0){
                                    clearInterval(this.secondsIntervalId);
                                    document.location.href = this.receiptData.redirectUrl;
                                }
                            }.bind(this), 1000)
                        } else if(res.data.data.finalStatus === "SUCCESS" || res.data.data.finalStatus === "ERROR") {
                            clearInterval(this.intervalId);
                            if(res.data.data.processorName === "APCO" && res.data.data.walletType === "NETELLER" && res.data.data.errorCode === "account-invalid" && res.data.data.amount >= 1000){
                                // with query, resulting in /register?plan=private
                                this.$router.push({ name: 'apcoDeposit', query: {
                                    paymentOption: res.data.data.walletType,
                                    brandUrl: res.data.data.brandUrl,
                                    language: res.data.data.language,
                                    customerId: res.data.data.platformCustomerId,
                                    ipAddress: res.data.data.ipAddress,
                                    playerCurrency: res.data.data.currencyCode,
                                    playerCurrencySymbol: res.data.data.currencySymbol,
                                    amount: res.data.data.amount,
                                    errorCode: res.data.data.errorCode,
                                } });
                            } else {
                                this.receiptData = res.data.data;
                            }
                        } else if(this.numberOfBackendCalls === 3) {
                            clearInterval(this.intervalId);
                            this.showLoader = false;
                            this.receiptData = res.data.data;
                            //this.getReceiptDataError = this.$t('error-2');
                        }
                    } else {
                        clearInterval(this.intervalId);
                        this.getReceiptDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                    }
                }).catch(e => {
                    clearInterval(this.intervalId);
                    this.showLoader = false;
                    this.getReceiptDataError = this.$t('error-2');
                    console.log(`%c Error in Receipt.vue - method: getReceiptData - ${e}`, 'color:red');
                });
            }
        }
    }
</script>

<style scoped></style>
